import { cn } from '@chaseweb/utils/cn'
import { DataTable } from '../../molecules/data-table'
import { textContentFromJSX } from '@chaseweb/utils/textContentFromJSX'
import type { ComponentPropsWithoutRef } from 'react'
import type React from 'react'

type stringOrElement = string | React.JSX.Element | React.JSX.Element[]

interface ResponsiveDataTableProps
  extends Omit<ComponentPropsWithoutRef<typeof DataTable.Root>, 'title'> {
  title?: stringOrElement
  description?: stringOrElement
  data: stringOrElement[][]
  footer?: stringOrElement
  hasColumnHeader?: boolean
  hasRowHeader?: boolean
  classNameTitle?: string
  classNameDescription?: string
  classNameFooter?: string
  classNameColumnHeader?: string
  classNameRowHeader?: string
}

export const ResponsiveDataTable = ({
  data,
  hasColumnHeader,
  hasRowHeader,
  title,
  description,
  footer,
  classNameTitle,
  classNameDescription,
  classNameFooter,
  classNameColumnHeader,
  classNameRowHeader = '',
  ...props
}: ResponsiveDataTableProps) => {
  const columnHeaderList = hasColumnHeader ? data[0] : undefined
  const bodyRowData = hasColumnHeader ? data.slice(1) : data
  const colsCount = data[0].length

  const rowHeaderColSize = colsCount <= 2 ? '' : `${100 / colsCount + 15}%`

  const desktopTable = (
    <DataTable.Container className="tw-hidden md:tw-block">
      {columnHeaderList && (
        <DataTable.Header>
          <DataTable.Row>
            {columnHeaderList.map((cell, cellIndex) => (
              <DataTable.ColumnHeaderCell
                key={textContentFromJSX(cell)}
                className={cn(classNameColumnHeader, {
                  [classNameRowHeader]: cellIndex === 0,
                })}
                style={cellIndex === 0 ? { width: rowHeaderColSize } : {}}
              >
                {cell}
              </DataTable.ColumnHeaderCell>
            ))}
          </DataTable.Row>
        </DataTable.Header>
      )}
      <DataTable.Body>
        {bodyRowData.map((row) => (
          <DataTable.Row key={textContentFromJSX(row.join())}>
            {row.map((cell, index) =>
              index === 0 && hasRowHeader ? (
                <DataTable.RowHeaderCell
                  key={textContentFromJSX(cell)}
                  className={classNameRowHeader}
                  style={{ width: rowHeaderColSize }}
                >
                  {cell}
                </DataTable.RowHeaderCell>
              ) : (
                <DataTable.Cell key={textContentFromJSX(cell)}>
                  {cell}
                </DataTable.Cell>
              ),
            )}
          </DataTable.Row>
        ))}
      </DataTable.Body>
    </DataTable.Container>
  )

  const mobileTable = (
    <DataTable.Container className="tw-hidden max-md:tw-block">
      <DataTable.Body>
        {bodyRowData.map((row) =>
          row.map((cell, cellIndex) => {
            const textContent = textContentFromJSX(cell)
            const hasColumnHeaderTextContent = !!textContentFromJSX(
              columnHeaderList?.[cellIndex],
            )

            if (hasRowHeader && cellIndex === 0) {
              return (
                <DataTable.Row
                  isHeader
                  key={textContent}
                  className={classNameColumnHeader}
                >
                  <DataTable.RowHeaderCell colSpan={2}>
                    {cell}
                  </DataTable.RowHeaderCell>
                </DataTable.Row>
              )
            }
            return (
              (!!textContent ||
                (!textContent && hasColumnHeaderTextContent)) && (
                <DataTable.Row
                  className={cn(
                    {
                      'tw-border-b-grey50 [&:not(:last-child)]:tw-border-b [&>td]:tw-pb-8':
                        cellIndex + 1 === colsCount,
                    },
                    classNameRowHeader,
                  )}
                  key={textContent}
                >
                  {hasColumnHeaderTextContent && (
                    <DataTable.RowHeaderCell>
                      {columnHeaderList?.[cellIndex]}
                    </DataTable.RowHeaderCell>
                  )}
                  <DataTable.Cell>{cell}</DataTable.Cell>
                </DataTable.Row>
              )
            )
          }),
        )}
      </DataTable.Body>
    </DataTable.Container>
  )

  return (
    <DataTable.Root {...props}>
      {title && (
        <DataTable.Title className={classNameTitle}>{title}</DataTable.Title>
      )}
      {description && (
        <DataTable.Description className={classNameDescription}>
          {description}
        </DataTable.Description>
      )}
      {desktopTable}
      {mobileTable}
      {footer && (
        <DataTable.Footer className={classNameFooter}>
          {footer}
        </DataTable.Footer>
      )}
    </DataTable.Root>
  )
}

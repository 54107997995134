'use client'

import { cn } from '@chaseweb/utils/cn'
import { useParallaxImage } from '@chaseweb/utils/useParallaxImage'
import type { HTMLAttributes } from 'react'
import { forwardRef, useImperativeHandle } from 'react'

const BackgroundImageWrapper = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    parallax?: boolean
  }
>(({ parallax, className, children, ...props }, ref) => {
  const [pictureRef, pictureWrapperRef] = useParallaxImage(parallax, 20)

  /* istanbul ignore next - can't test refs easily in jest */
  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(
    ref,
    () => pictureWrapperRef.current,
    [],
  )

  return (
    <div
      ref={pictureWrapperRef}
      data-ui-component={BackgroundImageWrapper.displayName}
      className={cn(
        'tw-absolute tw-left-0 tw-top-0 -tw-z-30 tw-h-full tw-w-full',
        className,
      )}
      {...props}
    >
      <div
        ref={pictureRef}
        className={cn(
          'tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full [&>*]:tw-absolute [&_*]:tw-h-full [&_*]:tw-w-full [&_*]:tw-object-cover [&_*]:tw-object-bottom md:[&_*]:tw-object-center',
          {
            '-tw-top-[10%] tw-h-[120%]': parallax,
          },
        )}
      >
        {children}
      </div>
    </div>
  )
})
BackgroundImageWrapper.displayName = 'Hero.BackgroundImageWrapper'

export { BackgroundImageWrapper }

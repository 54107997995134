import { forwardRef } from 'react'
import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
} from 'react'
import { cn } from '@chaseweb/utils/cn'
import { Heading, CardProfile, SVG } from '@chaseweb/ui-library/src/atoms'
import { Slot, Slottable } from '@radix-ui/react-slot'

interface RootProps extends HTMLAttributes<HTMLDivElement> {
  asChild?: boolean
  isClickable?: boolean
}

const Root = forwardRef<ElementRef<typeof CardProfile>, RootProps>(
  ({ className, isClickable, children, ...props }, ref) => {
    return (
      <CardProfile
        ref={ref}
        className={cn(
          'tw-group/imgcard tw-relative tw-z-0 tw-box-border tw-flex tw-h-[412px] tw-w-full tw-items-center tw-overflow-hidden lg:tw-h-[430px] xl:tw-h-[480px]',
          className,
        )}
        data-is-clickable={isClickable}
        shape="rounded-lg"
        {...props}
      >
        <div
          ref={ref}
          className={cn(
            'tw-absolute tw-right-0 tw-top-0 tw-h-[412px] tw-w-full tw-bg-image-card-gradient lg:tw-h-[430px] xl:tw-h-[480px]',
            className,
          )}
        />
        <Slottable>{children}</Slottable>
      </CardProfile>
    )
  },
)
Root.displayName = 'ImageCard.Root'

export interface ImageCardWrapperProps extends HTMLAttributes<HTMLDivElement> {
  asChild?: boolean
}

const LogoWrapper = forwardRef<HTMLDivElement, ImageCardWrapperProps>(
  ({ className, asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div'
    return (
      <Comp
        ref={ref}
        className={cn(
          'tw-absolute tw-left-0 tw-top-0 tw-z-10 tw-m-6 tw-flex tw-h-14 tw-w-14 tw-items-center tw-justify-center tw-rounded-[18px] tw-border-grey80 tw-bg-white lg:tw-m-8',
          className,
        )}
        {...props}
      />
    )
  },
)
LogoWrapper.displayName = 'ImageCard.LogoWrapper'

const ImageWrapper = forwardRef<HTMLDivElement, ImageCardWrapperProps>(
  ({ className, asChild, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          '-tw-z-30 tw-flex tw-h-full tw-w-full tw-items-center',
          '[&_*]:tw-h-full [&_*]:tw-w-full [&_*]:tw-object-cover [&_*]:tw-object-center [&_*]:tw-transition-transform [&_*]:tw-duration-300',
          'group-data-[is-clickable=true]/imgcard:group-hover/imgcard:[&_*]:tw-scale-110',
          className,
        )}
        {...props}
      />
    )
  },
)
ImageWrapper.displayName = 'ImageCard.ImageWrapper'

const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div
      className={cn(
        'tw-absolute tw-bottom-0 tw-flex tw-w-full tw-items-end tw-p-6 tw-text-white tw-duration-250 tw-ease-in-out lg:tw-p-8',
      )}
    >
      <div
        ref={ref}
        className={cn(
          'tw-w-full tw-text-left tw-transition-transform',
          className,
        )}
        {...props}
      >
        {children}
      </div>
      <div
        className={cn(
          'tw-ml-4 tw-hidden tw-h-fit tw-shrink-0 tw-rounded-[100px] tw-border tw-border-solid tw-border-grey70 tw-p-2 tw-transition-transform tw-duration-100',
          'group-hover/imgcard:tw-border-white group-hover/imgcard:tw-bg-white group-hover/imgcard:tw-text-blue30',
          'group-active/imgcard:tw-border-none group-active/imgcard:tw-bg-blue10 group-active/imgcard:tw-text-white',
          'group-data-[is-clickable=true]/imgcard:tw-flex',
        )}
      >
        <SVG name="RedirectIcon" className={cn('tw-h-6 tw-w-6')} />
      </div>
    </div>
  ),
)
Content.displayName = 'ImageCard.Content'

const Title = forwardRef<
  ElementRef<typeof Heading>,
  Omit<ComponentPropsWithoutRef<typeof Heading>, 'type'>
>(({ className, ...props }, ref) => (
  <Heading
    ref={ref}
    type="h3"
    className={cn('tw-text-xl tw-text-white', className)}
    {...props}
  />
))
Title.displayName = 'ImageCard.Title'

const Description = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p ref={ref} className={cn('tw-mt-2', className)} {...props} />
))
Description.displayName = 'ImageCard.Description'

export { Root, Content, LogoWrapper, ImageWrapper, Title, Description }

import type { ClassValue } from 'clsx'
import { clsx } from 'clsx'
import { extendTailwindMerge } from 'tailwind-merge'

const twMerge = extendTailwindMerge({
  prefix: 'tw-',
  theme: {
    spacing: ['15', '18', '22'],
    colors: [
      'grey90',
      'grey80',
      'grey70',
      'grey60',
      'grey50',
      'grey40',
      'grey30',
      'grey10',
      'blue90',
      'blue80',
      'blue70',
      'blue60',
      'blue50',
      'blue40',
      'blue30',
      'blue20',
      'blue10',
      'red90',
      'red70',
      'red50',
      'red40',
      'red30',
      'orange90',
      'orange70',
      'orange50',
      'orange40',
      'orange30',
      'yellow90',
      'yellow70',
      'yellow50',
      'yellow40',
      'green90',
      'green70',
      'green50',
      'green40',
      'teal90',
      'teal70',
      'teal50',
      'teal40',
      'primary',
      'primaryText',
      'primaryBorder',
      'primaryHover',
      'primaryHoverText',
      'primaryHoverBorder',
      'primaryFocus',
      'primaryFocusBorder',
      'primaryActive',
      'primaryActiveBorder',
      'secondary',
      'secondaryText',
      'secondaryBorder',
      'secondaryHover',
      'secondaryHoverText',
      'secondaryHoverBorder',
      'secondaryFocus',
      'secondaryFocusText',
      'secondaryFocusBorder',
      'secondaryActive',
      'secondaryActiveText',
      'secondaryActiveBorder',
      'disabled',
      'disabledText',
      'disabledBorder',
      'link',
      'transparent',
      'focusRing',
      'background',
      'text',
      'white',
      'heroOverlayGradient',
    ],
    screen: ['sm', 'md', 'lg', 'xl', '2xl'],
  },
  classGroups: {
    'bg-size': [{ bg: ['auto', 'cover', 'contain', 'icon-size'] }],
    'bg-image': [
      {
        bg: [
          'dash',
          'icon-arrow-left',
          'icon-arrow-right',
          'blue-diagonal',
          'grey-diagonal',
          'chase-octagon',
          'image-card-gradient',
        ],
      },
    ],
    shadow: [{ shadow: ['underscore', 'underscore-lg', 'popup'] }],
    duration: [{ duration: ['250'] }],
    rounded: [{ rounded: ['full', '4xl', '5xl', '6xl'] }],
  },
})
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

import type { ButtonHTMLAttributes } from 'react'
import { forwardRef } from 'react'
import { Slot, Slottable } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@chaseweb/utils/cn'
import { SVG } from '../svg'

const buttonVariants = cva(
  [
    'tw-no-underline',
    'tw-inline-flex',
    'tw-transition-colors',
    'focus-visible:tw-outline-none',
    'focus-visible:tw-ring-2',
    'focus-visible:tw-ring-focusRing',
  ],
  {
    variants: {
      variant: {
        primary: [
          'tw-rounded-5xl',
          'tw-items-center',
          'tw-border',
          'tw-border-solid',
          'tw-font-semibold',
          'tw-justify-center',
          'tw-border-primaryBorder',
          'tw-bg-primary',
          'tw-text-primaryText',
          'hover:tw-border-primaryHoverBorder',
          'hover:tw-bg-primaryHover',
          'hover:tw-text-primaryHoverText',
          'focus-visible:tw-border-primaryFocusBorder',
          'focus-visible:tw-bg-primaryFocus',
          'active:tw-border-primaryActiveBorder',
          'active:tw-bg-primaryActive',
        ],
        secondary: [
          'tw-rounded-5xl',
          'tw-items-center',
          'tw-border',
          'tw-border-solid',
          'tw-font-semibold',
          'tw-justify-center',
          'tw-border-secondaryBorder',
          'tw-bg-secondary',
          'tw-text-secondaryText',
          'hover:tw-border-secondaryHoverBorder',
          'hover:tw-bg-secondaryHover',
          'hover:tw-text-secondaryHoverText',
          'focus-visible:tw-border-secondaryFocusBorder',
          'focus-visible:tw-bg-secondaryFocus',
          'focus-visible:tw-text-secondaryFocusText',
          'active:tw-border-secondaryActiveBorder',
          'active:tw-bg-secondaryActive',
          'active:tw-text-secondaryActiveText',
        ],
        link: [
          'tw-inline',
          'tw-p-0',
          'tw-m-0',
          'tw-border-0',
          'tw-text-link',
          'tw-underline-offset-4',
          'tw-underline',
          'tw-decoration-transparent',
          'active:tw-text-blue10',
          'hover:tw-decoration-current',
          'hover:tw-text-blue30',
          'focus-visible:tw-ring-offset-1',
          'focus-visible:tw-outline-none',
          'focus-visible:tw-ring-2',
          'focus-visible:tw-ring-focusRing',
        ],
      },
      size: {
        md: ['tw-px-6', 'tw-py-3', 'tw-min-w-[112px]'],
        sm: ['tw-px-4', 'tw-py-2.5'],
      },
      border: {
        default: '',
        borderless: 'tw-border-transparent',
      },
      disabled: {
        true: [
          'tw-pointer-events-none',
          'disabled:tw-border-disabledBorder',
          'disabled:tw-bg-disabled',
          'disabled:tw-text-disabledText',
        ],
      },
      noLinkUnderline: {
        true: '',
      },
      invertLinkUnderline: {
        true: '',
      },
    },
    compoundVariants: [
      {
        variant: 'link',
        disabled: true,
        class: 'disabled:tw-border-0',
      },
      {
        variant: 'link',
        invertLinkUnderline: true,
        class: 'tw-decoration-current hover:tw-decoration-transparent',
      },
      {
        variant: 'link',
        noLinkUnderline: true,
        class: 'tw-decoration-transparent hover:tw-decoration-transparent',
      },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'md',
      border: 'default',
      disabled: undefined,
      invertLinkUnderline: undefined,
      noLinkUnderline: undefined,
    },
  },
)

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  disabled?: boolean
  asChild?: boolean
  noExternalIcon?: boolean
  onClick?: (ev?: any) => void
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      border,
      role,
      tabIndex,
      disabled,
      children,
      asChild = false,
      noLinkUnderline,
      noExternalIcon,
      invertLinkUnderline,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    const customSize = variant === 'link' ? null : size
    const customRole = role ?? (variant === 'link' ? 'link' : 'button')
    return (
      <Comp
        className={cn(
          'tw-transition-colors',
          buttonVariants({
            variant,
            size: customSize,
            border,
            disabled,
            invertLinkUnderline,
            noLinkUnderline,
          }),
          { 'tw-group/button': !noExternalIcon && asChild },
          className,
        )}
        ref={ref}
        tabIndex={tabIndex ?? 0}
        disabled={disabled}
        data-variant={variant}
        {...props}
        role={customRole}
      >
        <Slottable>{children}</Slottable>
        {!noExternalIcon && asChild && (
          <SVG
            name="ExternalLink"
            aria-hidden
            className={cn(
              `tw-hidden tw-w-[1.4em] tw-align-text-bottom group-[&[href^=http]]/button:tw-inline group-[&[target=\\_blank]]/button:tw-inline`,
            )}
          />
        )}
      </Comp>
    )
  },
)
Button.displayName = 'Button'

export { Button }

import type { SVGSpriteComponent } from '../types'

const Clear: SVGSpriteComponent = ({ children, spriteFilePath, ...props }) => {
  return (
    <symbol fill="none" {...props}>
      {children}
      <path
        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM15.53 14.47C15.6037 14.5387 15.6628 14.6215 15.7038 14.7135C15.7448 14.8055 15.7668 14.9048 15.7686 15.0055C15.7704 15.1062 15.7518 15.2062 15.7141 15.2996C15.6764 15.393 15.6203 15.4778 15.549 15.549C15.4778 15.6203 15.393 15.6764 15.2996 15.7141C15.2062 15.7518 15.1062 15.7704 15.0055 15.7686C14.9048 15.7668 14.8055 15.7448 14.7135 15.7038C14.6215 15.6628 14.5387 15.6037 14.47 15.53L12 13.0605L9.53 15.53C9.38783 15.6625 9.19978 15.7346 9.00548 15.7312C8.81118 15.7277 8.6258 15.649 8.48838 15.5116C8.35097 15.3742 8.27226 15.1888 8.26883 14.9945C8.2654 14.8002 8.33752 14.6122 8.47 14.47L10.94 12L8.47 9.53C8.33752 9.38783 8.2654 9.19978 8.26883 9.00548C8.27226 8.81118 8.35097 8.62579 8.48838 8.48838C8.6258 8.35097 8.81118 8.27225 9.00548 8.26882C9.19978 8.2654 9.38783 8.33752 9.53 8.47L12 10.94L14.47 8.47C14.6122 8.33752 14.8002 8.2654 14.9945 8.26882C15.1888 8.27225 15.3742 8.35097 15.5116 8.48838C15.649 8.62579 15.7278 8.81118 15.7312 9.00548C15.7346 9.19978 15.6625 9.38783 15.53 9.53L13.0605 12L15.53 14.47Z"
        fill="#6E7275"
      />
    </symbol>
  )
}
Clear.displayName = 'SVG.Clear'
const ClearConfigProps = { width: '24', height: '24', viewBox: '0 0 24 24' }
export { Clear, ClearConfigProps }

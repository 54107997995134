'use client'

import {
  createContext,
  useContext,
  useState,
  useMemo,
  useRef,
  useEffect,
} from 'react'
import type {
  ReactNode,
  FC,
  Dispatch,
  SetStateAction,
  MutableRefObject,
} from 'react'

interface CarouselContextProps {
  variant: 'fullwidth' | 'background' | 'contained' | 'custom'
  maxPageSize: 1 | 2 | 3
  contentRefList: MutableRefObject<HTMLLIElement[]>
  containerRef: MutableRefObject<HTMLUListElement | null>
  childrenCount: number
  setChildrenCount: Dispatch<SetStateAction<number>>
  pageCount: number
  setPageCount: Dispatch<SetStateAction<number>>
  pageSize: number
  setPageSize: Dispatch<SetStateAction<number>>
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
}

const NotImplementedErrorMsg = 'Function not implemented.'

export const CarouselContext = createContext<CarouselContextProps>({
  variant: 'fullwidth',
  maxPageSize: 3,
  contentRefList: { current: [] },
  containerRef: { current: null },
  childrenCount: 0,
  setChildrenCount: (): void => {
    throw new Error(NotImplementedErrorMsg)
  },
  pageCount: 0,
  setPageCount: (): void => {
    throw new Error(NotImplementedErrorMsg)
  },
  pageSize: 0,
  setPageSize: (): void => {
    throw new Error(NotImplementedErrorMsg)
  },
  currentPage: 0,
  setCurrentPage: (): void => {
    throw new Error(NotImplementedErrorMsg)
  },
})

export const useCarouselContext = () => useContext(CarouselContext)

export const CarouselContextProvider: FC<{
  variant: 'fullwidth' | 'background' | 'contained' | 'custom'
  maxPageSize: 1 | 2 | 3
  children?: ReactNode | ReactNode[]
  currentPage?: number
  pageSize?: number
  pageCount?: number
  childrenCount?: number
  contentRefList?: HTMLLIElement[]
  containerRef?: HTMLUListElement
}> = (props) => {
  const [pageCount, setPageCount] = useState<number>(props.pageCount ?? 1)
  const [currentPage, setCurrentPage] = useState<number>(props.currentPage ?? 0)
  const [pageSize, setPageSize] = useState<number>(props.pageSize ?? 0)
  const [childrenCount, setChildrenCount] = useState<number>(
    props.childrenCount ?? 0,
  )
  const contentRefList = useRef<HTMLLIElement[]>(props.contentRefList ?? [])
  const containerRef = useRef<HTMLUListElement | null>(
    props.containerRef ?? null,
  )

  useEffect(() => {
    if (pageSize && containerRef.current) {
      const widthItem = contentRefList.current[0].clientWidth
      const widthContainer = containerRef.current.clientWidth
      const offset =
        props.variant !== 'background' && pageSize === 1
          ? (widthContainer - widthItem - 8) / 2 - 4
          : 0
      containerRef.current.scrollTo({
        left: widthItem * pageSize * currentPage - offset,
        behavior: 'smooth',
      })
    }
  }, [currentPage, pageSize])

  const contextValue = useMemo(
    () => ({
      variant: props.variant,
      maxPageSize: props.maxPageSize,
      contentRefList,
      containerRef,
      childrenCount,
      setChildrenCount,
      pageCount,
      setPageCount,
      pageSize,
      setPageSize,
      currentPage,
      setCurrentPage,
    }),
    [props.variant, pageCount, currentPage, childrenCount],
  )

  return (
    <CarouselContext.Provider value={contextValue}>
      {props.children}
    </CarouselContext.Provider>
  )
}

'use client'
import { m, LazyMotion, domAnimation } from 'framer-motion'
import { motionValues } from '@chaseweb/tailwindconfig/motion'
import type {
  ElementRef,
  ComponentPropsWithoutRef,
  ReactNode,
  HTMLAttributes,
} from 'react'
import {
  Children,
  forwardRef,
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { cn } from '@chaseweb/utils/cn'
import { SVG } from '../../atoms'

export type ModalType = 'fullPage' | 'bottomAnchored'

const Root = DialogPrimitive.Root
Root.displayName = 'Modal.Root'

const Trigger = DialogPrimitive.Trigger
Trigger.displayName = 'Modal.Trigger'

const Overlay = forwardRef<
  ElementRef<typeof DialogPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, children, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      'tw-fixed tw-inset-0 tw-z-50 tw-overflow-y-auto tw-bg-[rgba(33,38,48,0.5)]',
      className,
    )}
    {...props}
  >
    <div className="tw-flex tw-min-h-full tw-flex-col tw-justify-center">
      {children}
    </div>
  </DialogPrimitive.Overlay>
))
Overlay.displayName = 'Modal.Overlay'

const Box = forwardRef<
  ElementRef<typeof DialogPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay> & {
    currentStep?: number
    variant?: ModalType
  }
>(
  (
    { className, currentStep = 0, variant = 'fullPage', children, ...props },
    ref,
  ) => {
    const [visibleVariant, setVisibleVariant] = useState<ModalType>(variant)
    const containerRef = useRef<HTMLDivElement>(null)
    const contentRefList = useRef<HTMLDivElement[]>([])

    const updateSizeContent = useCallback(() => {
      if (containerRef.current) {
        if (
          window.innerWidth < 769 &&
          (contentRefList.current[currentStep].offsetHeight + 100 >
            window.innerHeight ||
            variant === 'fullPage')
        ) {
          containerRef.current.style.height = '100%'
          if (visibleVariant !== 'fullPage') {
            setVisibleVariant('fullPage')
          }
        } else {
          containerRef.current.style.height = `${contentRefList.current[currentStep].offsetHeight}px`
          if (visibleVariant !== variant) {
            setVisibleVariant(variant)
          }
        }
      }
    }, [currentStep, containerRef.current, visibleVariant])

    useEffect(() => {
      setTimeout(() => {
        updateSizeContent()
      })

      window.addEventListener('resize', updateSizeContent, false)
      return () => {
        window.removeEventListener('resize', updateSizeContent, false)
      }
    }, [currentStep, containerRef.current, visibleVariant])

    const handleRefAssign = (index: number) => (el: HTMLDivElement) => {
      contentRefList.current[index] = el
    }

    return (
      <DialogPrimitive.Portal>
        <Overlay ref={ref} {...props} data-test={currentStep}>
          <DialogPrimitive.Content
            className={cn(
              'tw-fixed tw-bottom-0 tw-mx-auto tw-max-h-full tw-overflow-y-auto tw-bg-background tw-shadow-modal',
              'md:tw-relative md:tw-my-12 md:tw-max-w-xl md:tw-animate-mount md:tw-rounded-3xl ',
              {
                'tw-rounded-t-3xl': visibleVariant === 'bottomAnchored',
                'tw-rounded-none tw-shadow-none': visibleVariant === 'fullPage',
              },
            )}
            onOpenAutoFocus={updateSizeContent}
          >
            <div
              ref={containerRef}
              className={cn(
                'tw-grid tw-grid-cols-[1fr] tw-overflow-y-auto tw-px-4 tw-py-6 md:tw-p-8',
                'tw-transition-[height] tw-duration-moderate4 tw-ease-standard md:tw-box-content',
                {
                  'tw-box-content tw-overflow-hidden':
                    visibleVariant === 'bottomAnchored',
                },
                className,
              )}
            >
              <LazyMotion features={domAnimation}>
                {Children.toArray(children)
                  .filter(Boolean)
                  .map((child: ReactNode, childIndex) => {
                    if (currentStep !== childIndex) return null
                    return (
                      <m.div
                        key={childIndex}
                        transition={{
                          ease: motionValues.easing.sharp,
                          duration: motionValues.duration.fast3,
                        }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <div
                          ref={handleRefAssign(childIndex)}
                          className={cn(
                            'tw-relative tw-col-start-1 tw-row-start-1 tw-h-fit tw-w-full',
                          )}
                        >
                          {child}
                        </div>
                      </m.div>
                    )
                  })}
              </LazyMotion>
            </div>
          </DialogPrimitive.Content>
        </Overlay>
      </DialogPrimitive.Portal>
    )
  },
)
Box.displayName = 'Modal.Box'

const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} {...props} />
  },
)
Content.displayName = 'Modal.Content'

const Close = forwardRef<
  ElementRef<typeof DialogPrimitive.Close>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Close> & { label: string }
>(({ className, children, label, ...props }, ref) => {
  return (
    <DialogPrimitive.Close
      className={cn(
        'tw-absolute tw-right-0 tw-top-0 tw-rounded-full tw-border tw-p-0.5 tw-opacity-70 tw-ring-offset-background tw-transition-opacity hover:tw-opacity-100 focus:tw-outline-none focus:tw-ring-4 disabled:tw-pointer-events-none',
        className,
      )}
      ref={ref}
      aria-label={label}
      {...props}
    >
      <SVG name="Close" />
    </DialogPrimitive.Close>
  )
})
Close.displayName = 'Modal.Close'

export { Root, Trigger, Box, Content, Close }

import type { SVGSpriteComponent } from '../types'

const NutmegIcon: SVGSpriteComponent = ({
  children,
  spriteFilePath,
  ...props
}) => {
  return (
    <symbol fill="none" {...props}>
      {children}
      <rect x="0.5" y="0.5" width="39" height="39" rx="11.5" fill="white" />
      <path
        d="M22.6779 11V11.297C24.2545 11.5347 24.4881 13.2574 24.4881 15.7525V22.2277L17.2474 11.0594H12.1089V11.3564C12.9848 11.5347 13.6271 12.2475 13.7439 13.1386V24.2475C13.7439 26.6832 13.6855 28.6436 12.3425 28.703V29H16.8387V28.703C15.2621 28.4653 15.0285 26.7426 15.0285 24.2475V13.8515L25.0136 28.9406H25.7727V15.7525C25.7727 13.3168 25.8311 11.3564 27.1741 11.297V11H22.6779Z"
        fill="#2FB153"
      />
      <path
        d="M27.1738 27.0374C27.1738 25.9669 28.0568 25.1343 29.1163 25.1343C30.1758 25.1343 30.9999 25.9669 30.9999 27.0374C30.9999 28.108 30.1758 28.9406 29.1163 29C28.0568 28.9406 27.1738 28.108 27.1738 27.0374Z"
        fill="#2FB153"
      />
      <rect x="0.5" y="0.5" width="39" height="39" rx="11.5" stroke="#E3E6EC" />
    </symbol>
  )
}
NutmegIcon.displayName = 'SVG.NutmegIcon'
const NutmegIconConfigProps = { width: '40px', viewBox: '0 0 40 40' }
export { NutmegIcon, NutmegIconConfigProps }

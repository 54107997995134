import { forwardRef } from 'react'
import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
} from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cn } from '@chaseweb/utils/cn'
import { CardProfile } from '../../atoms/card-profile'
import { Heading } from '../../atoms/heading'
import { Button } from '../../atoms/button'

const Root = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    gradientDirection?: 'bottomRight' | 'topLeft' | 'leftRight'
    classNameDiagonal?: string
    classNameGradient?: string
  }
>(
  (
    {
      className,
      classNameDiagonal,
      classNameGradient,
      children,
      gradientDirection,
      ...props
    },
    ref,
  ) => (
    <CardProfile
      ref={ref}
      className={cn(
        'tw-relative tw-z-0 tw-flex tw-flex-col tw-overflow-hidden md:tw-flex-row',
        'tw-w-full',
        className,
      )}
      {...props}
      shape="cut-lg"
      data-ui-component={Root.displayName}
    >
      {children}
      {gradientDirection && (
        <div
          className={cn(
            'tw-absolute -tw-z-20 tw-h-full tw-w-full tw-from-heroOverlayGradient tw-to-[75.1%] md:tw-w-full',
            {
              'tw-bg-gradient-to-b md:tw-bg-gradient-to-r':
                gradientDirection === 'bottomRight',
              'tw-bg-gradient-to-t md:tw-bg-gradient-to-l':
                gradientDirection === 'topLeft',
            },
            classNameGradient,
          )}
        />
      )}
      <div
        className={cn(
          'tw-absolute -tw-z-40 tw-h-full tw-w-full tw-bg-blue40 tw-bg-blue-diagonal',
          classNameDiagonal,
        )}
      />
    </CardProfile>
  ),
)
Root.displayName = 'Hero.Root'

const Container = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        className={cn(
          'tw-container',
          'tw-min-h-[760px] max-md:tw-px-2 md:tw-min-h-[480px] lg:tw-min-h-[600px] xl:tw-min-h-[712px]',
        )}
      >
        <div
          ref={ref}
          data-ui-component={Container.displayName}
          className={cn(
            'tw-flex tw-h-full tw-w-full tw-flex-col tw-gap-8 tw-py-6 md:tw-flex-row md:tw-justify-between md:tw-py-16 lg:tw-py-24',
            'md:-tw-mx-4 lg:-tw-mx-6 xl:tw-mx-0',
            className,
          )}
          {...props}
        />
      </div>
    )
  },
)
Container.displayName = 'Hero.Container'

const Content = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    asChild?: boolean
  }
>(({ className, asChild, ...props }, ref) => {
  const Comp = asChild ? Slot : 'div'
  return (
    <Comp
      ref={ref}
      data-ui-component={Content.displayName}
      className={cn(
        'tw-flex tw-flex-col tw-justify-center md:tw-max-w-[288px] lg:tw-max-w-md',
        className,
      )}
      {...props}
    />
  )
})
Content.displayName = 'Hero.Content'

const ImageWrapper = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        data-ui-component={ImageWrapper.displayName}
        className={cn(
          'tw-relative tw-flex tw-h-full tw-w-full tw-shrink-0 tw-items-center md:tw-w-1/2',
          '[&>*]:tw-right-0 [&>*]:-tw-z-10 md:[&>*]:tw-absolute md:[&>*]:tw-inline-table [&_*]:tw-h-full [&_*]:tw-max-h-full [&_*]:tw-w-full [&_*]:tw-max-w-full [&_*]:tw-object-contain md:[&_*]:tw-object-cover',
          className,
        )}
        {...props}
      />
    )
  },
)
ImageWrapper.displayName = 'Hero.ImageWrapper'

const Title = forwardRef<
  ElementRef<typeof Heading>,
  PartialBy<ComponentPropsWithoutRef<typeof Heading>, 'type'>
>(({ className, ...props }, ref) => (
  <Heading
    type="h1"
    ref={ref}
    data-ui-component={Title.displayName}
    className={cn(
      'tw-text-[38px] tw-font-light tw-leading-[1.2] tw-text-primaryText lg:tw-text-[64px] lg:tw-leading-[1.15]',
      className,
    )}
    {...props}
  />
))
Title.displayName = 'Hero.Title'

const Description = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      data-ui-component={Description.displayName}
      className={cn('tw-mt-6 tw-font-semibold tw-text-primaryText', className)}
      {...props}
    />
  ),
)
Description.displayName = 'Hero.Description'

const Cta = forwardRef<
  ElementRef<typeof Button>,
  ComponentPropsWithoutRef<typeof Button>
>(({ className, ...props }, ref) => (
  <Button
    ref={ref}
    data-ui-component={Cta.displayName}
    variant="primary"
    className={cn('tw-mt-6 tw-w-fit tw-font-semibold', className)}
    {...props}
  />
))
Cta.displayName = 'Hero.Cta'

export { Root, Container, Content, ImageWrapper, Title, Description, Cta }

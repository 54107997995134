import { Children, forwardRef } from 'react'
import type { HTMLAttributes } from 'react'
import { cn } from '@chaseweb/utils/cn'
import { cva, type VariantProps } from 'class-variance-authority'

const rootVariants = (childrenCount: number) =>
  cva('', {
    variants: {
      variant: {
        narrative: {
          'tw-group/narrative tw-grid tw-grid-cols-1 tw-gap-10 lg:tw-gap-6':
            childrenCount,
          'lg:tw-grid-cols-2': childrenCount === 2,
          'lg:tw-grid-cols-3': childrenCount === 3,
        },
        instruction:
          'tw-group/instruction tw-grid tw-grid-cols-1 tw-gap-6 md:tw-grid-cols-2 lg:tw-grid-cols-3',

        product: {
          'tw-group/product tw-grid tw-grid-cols-1 tw-gap-6 md:tw-grid-cols-2':
            childrenCount,
          'lg:tw-grid-cols-3': childrenCount !== 4,
          'lg:tw-grid-cols-2 xl:tw-grid-cols-4': childrenCount === 4,
        },
        image: {
          'tw-group/image tw-grid tw-w-full tw-grid-cols-1 tw-gap-6':
            childrenCount,
          'md:tw-grid-cols-2': childrenCount === 2,
          'md:tw-grid-cols-2 lg:tw-grid-cols-3': childrenCount >= 3,
        },
      },
    },
  })

interface RootProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<ReturnType<typeof rootVariants>> {
  asChild?: boolean
  stripedStart?: 'left' | 'right'
  variant: 'instruction' | 'product' | 'image' | 'narrative'
}

const Root = forwardRef<HTMLDivElement, RootProps>(
  ({ className, children, stripedStart, variant, ...props }, ref) => {
    const childrenCount = Children.count(children)

    return (
      <div
        ref={ref}
        data-striped={!!stripedStart}
        data-variant={variant}
        className={cn(rootVariants(childrenCount)({ variant, className }))}
        {...props}
      >
        {stripedStart === 'right' && <span className="tw-hidden" />}
        {children}
      </div>
    )
  },
)
Root.displayName = 'CardGrid.Root'

export { Root }

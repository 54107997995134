import { forwardRef } from 'react'
import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
} from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@chaseweb/utils/cn'
import { Slot } from '@radix-ui/react-slot'
import { Button, CardProfile, Heading } from '../../atoms'

const rootVariants = cva('', {
  variants: {
    variant: {
      primary: 'tw-bg-blue-diagonal',
      secondary: 'tw-bg-grey-diagonal',
      tertiary: 'tw-bg-grey90',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
})

interface RootProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof rootVariants> {}

const Root = forwardRef<HTMLDivElement, RootProps>(
  ({ className, children, variant, ...props }, ref) => {
    return (
      <div
        data-ui-component={Root.displayName}
        data-variant={variant}
        className={cn(
          'w-w-full tw-relative',
          {
            'tw-group/promo-primary': variant === 'primary',
            'tw-group/promo-secondary': variant === 'secondary',
          },
          className,
        )}
        ref={ref}
        {...props}
      >
        <CardProfile
          shape={variant === 'primary' ? 'cut-lg' : 'rounded-lg'}
          className={cn(
            rootVariants({ variant }),
            'tw-absolute -tw-z-10 tw-h-full tw-w-full max-md:tw-hidden',
          )}
        />
        <div
          className={cn(
            'tw-z-10 tw-flex tw-flex-col',
            'max-md:tw-overflow-hidden max-md:tw-rounded-3xl',
            'md:tw-mx-auto md:tw-flex md:tw-max-w-[1280px] md:tw-flex-row md:tw-justify-between md:tw-pl-16 md:tw-pr-20',
            {
              'max-md:tw-bg-blue-diagonal': variant === 'primary',
              'max-md:tw-bg-grey-diagonal': variant === 'secondary',
              'max-md:tw-bg-grey90 md:tw-pl-12': variant === 'tertiary',
            },
          )}
        >
          {children}
        </div>
      </div>
    )
  },
)
Root.displayName = 'Promo.Root'

const imageVariants = cva(
  'tw-max-h-[400px] tw-w-full tw-self-end md:tw-max-h-[640px] lg:tw-max-h-none',
  {
    variants: {
      variant: {
        fit: 'tw-object-contain md:tw-max-w-[320px] lg:tw-max-w-[450px] 2xl:tw-max-w-[580px]',
        overflow:
          'tw-object-contain md:-tw-mt-7 md:tw-max-w-[350px] lg:tw-max-w-[420px] xl:-tw-mt-14 xl:tw-max-w-[500px]',
        background:
          'md:ui-promo-image-clip-path tw-right-0 tw-object-cover md:tw-absolute md:tw-h-full md:tw-w-3/5 md:tw-rounded-r-3xl',
      },
    },
    defaultVariants: {
      variant: 'fit',
    },
  },
)

interface ImageProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof imageVariants> {
  asChild?: boolean
}

const ImageWrapper = forwardRef<HTMLDivElement, ImageProps>(
  ({ className, variant, asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div'
    return (
      <Comp
        ref={ref}
        data-ui-component={ImageWrapper.displayName}
        className={cn(imageVariants({ variant, className }))}
        {...props}
      />
    )
  },
)

ImageWrapper.displayName = 'Promo.ImageWrapper'

const ContentWrapper = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      data-ui-component={ContentWrapper.displayName}
      className={cn(
        'tw-flex tw-flex-col tw-justify-center tw-p-6 tw-pb-8 md:tw-max-w-[45%] md:tw-px-0 md:tw-py-12',
        className,
      )}
      {...props}
    >
      <div className="tw-text-left">{children}</div>
    </div>
  )
})

ContentWrapper.displayName = 'Promo.ContentWrapper'

const Title = forwardRef<
  ElementRef<typeof Heading>,
  PartialBy<ComponentPropsWithoutRef<typeof Heading>, 'type'>
>(({ className, type, ...props }, ref) => (
  <Heading
    type={type ?? 'h2'}
    ref={ref}
    data-ui-component={Title.displayName}
    className={cn(
      'tw-mb-4',
      'group-[&]/promo-primary:tw-text-white',
      className,
    )}
    {...props}
  />
))
Title.displayName = 'Promo.Title'

const Description = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      data-ui-component={Description.displayName}
      className={cn(
        'tw-mb-4',
        'group-[&]/promo-primary:tw-text-white',
        className,
      )}
      {...props}
    />
  ),
)
Description.displayName = 'Promo.Description'

const Cta = forwardRef<
  ElementRef<typeof Button>,
  ComponentPropsWithoutRef<typeof Button>
>(({ className, ...props }, ref) => (
  <Button
    ref={ref}
    data-ui-component={Cta.displayName}
    variant="secondary"
    className={cn(
      'tw-mt-2',
      'group-[&]/promo-primary:tw-border-none',
      className,
    )}
    {...props}
  />
))
Cta.displayName = 'Promo.Cta'

export { Root, ImageWrapper, ContentWrapper, Title, Description, Cta }
